import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Title } from "components/atoms/title";
import { Text } from "components/atoms/text";
import { Badge } from "components/atoms/badge";

const MindmapCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 316px;
  width: 316px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 16px 30px 50px;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  &:first-child {
    margin-left: 10px;
  }
  &:last-child {
    margin-right: 10px;
  }
  &:not(:last-child) {
    margin-right: 60px;
  }

  &:hover {
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: ${({ theme }) => theme.device.portraitTablet}px) {
    min-width: 280px;
    width: 280px;
    &:first-child {
      margin-left: 40px;
    }

    &:not(:last-child) {
      margin-right: 40px;
    }

    &:last-child {
      margin-right: 40px;
    }
  }
`;

const OnlyHover = styled.div<{ isHovering: boolean }>`
  opacity: ${({ isHovering }) => (isHovering ? "1" : "0")};
  transition: 0.25s ease-in-out;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
`;

const MilestoneSubTitle = styled.span`
  color: #1b1b1b;
  font-family: "Post Grotesk";
`;

const ProgressBar = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  height: 27px;
  align-self: flex-end;
  padding: 4px 0 4px 30px;
  border-radius: 2px;
  color: #000;
  background: linear-gradient(
    270deg,
    #3735359d 0%,
    rgba(185, 180, 163, 0) 110.56%
  );
`;

export const MindmapCard = ({
  datum,
  openModal,
  setModalContent,
}: {
  datum: any;
  openModal: () => void;
  setModalContent: (datum: any) => void;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const { t } = useTranslation();

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleModal = () => {
    setModalContent(datum);
    openModal();
  };
  return (
    <MindmapCardStyle
      onClick={handleModal}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <Title
        type="h3"
        fontSize="67"
        mobilefontsize="40"
        fontFamily="Post Grotesk Thin"
        align="center"
        color="#A5A1A1"
        mb={32}
      >
        {datum.number}
      </Title>
      <Title type="h2" fontSize="28" mobilefontsize="20" mb={32} align="center">
        {datum.title}
      </Title>

      <OnlyHover isHovering={isHovering}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "32px",
          }}
        >
          {datum.hasObjectiveLabel && (
            <Text
              fontSize="18"
              fontFamily="Post Grotesk Thin"
              mr={8}
              align="center"
            >
              {datum.caption}
            </Text>
          )}
        </div>
      </OnlyHover>
    </MindmapCardStyle>
  );
};
