import XRVideo from "assets/video/rugby.mp4";
import Watches from "assets/video/watches.mp4";
import Home from "assets/video/homeVideo.mp4";
import Meta from "assets/video/meta-watches.mp4";

export const MINDMAP_DATA = [
  {
    number: "01",
    title: "XR Short Films",
    caption: "High-End Mixed Reality Films for Your Brand's Story",
    objective: "The Hub",
    hasObjectiveLabel: true,
    milestoneTitle: "Next -",
    milestoneSubTitle: "The OC",
    milestoneLabel: "in progress",
    hasMilestoneLabel: true,
    video: XRVideo,
    contents: [
      {
        title: "Immersive Storytelling",
        description:
          "Craft a resonating narrative that ties digital elements to the brand's message.",
      },
      {
        title: "Interactive Engagement",
        description:
          "Allow users to actively interact, deepening their emotional connection.",
      },
      {
        title: "Authenticity & Relevance",
        description:
          "Ensure digital components align with the brand's ethos and current trends.",
      },
    ],
  },
  {
    number: "02",
    title: "Immersive Experiences",
    caption: "Craft High-End Virtual Experiences for Your Customers",
    objective: "The Hub",
    hasObjectiveLabel: true,
    milestoneTitle: "Next -",
    milestoneSubTitle: "The OC",
    milestoneLabel: "in progress",
    hasMilestoneLabel: true,
    progressionPercentage: 70,
    video: Watches,
    contents: [
      {
        title: "Brand-Specific Virtuality",
        description:
          "Your unique brand deserves bespoke virtual experiences. We amplify your brand voice, ensuring you shine in the digital realm.",
      },
      {
        title: "Immersive Storytelling",
        description:
          "Harnessing spatial computing, we craft stories that aren't just viewed, but lived. With us, your audience steps inside your narrative.",
      },
      {
        title: "Adaptable Across Devices",
        description:
          "From mobiles to VR, our designs flex to fit any platform, guaranteeing a smooth user experience everywhere.",
      },
      {
        title: "Emotive Engagement",
        description:
          "Beyond aesthetics, we captivate emotions, forging powerful brand bonds and loyalty.",
      },
      {
        title: "Strengthened Customer Ties",
        description:
          "Our experiences don't just showcase; they connect, cultivating lasting brand-customer relationships.",
      },
    ],
  },
  {
    number: "03",
    title: "Artificial Intelligence",
    caption: "Harness the Power of AI to Drive Business Performance",
    objective: "OG Pass",
    hasObjectiveLabel: true,
    milestoneTitle: "Next -",
    milestoneSubTitle: "Tinies",
    milestoneLabel: "in progress",
    hasMilestoneLabel: true,
    progressionPercentage: 20,
    video: Meta,
    contents: [
      {
        title: "Bespoke AI",
        description:
          "Tailored AI solutions aligning with your distinct business aspirations. We don't just design; we integrate AI that elevates your strategy.",
      },
      {
        title: "AI Automation",
        description:
          "Swap routine tasks with AI-driven efficiency. Free your team for strategy, while we manage the operations with AI precision.",
      },
      {
        title: "Predictive Insights",
        description:
          "Harness AI to decode customer trends. Stay a step ahead, predict behaviors, and refine offerings with data-driven foresight.",
      },
      {
        title: "Customized Interactions",
        description:
          "Heighten engagement with AI-curated experiences. Make every touchpoint echo your customer’s desires.",
      },
      {
        title: "Performance Amplified",
        description:
          "AI that doesn't just function, but boosts ROI. Dive into advanced AI operations to uplift metrics, cut costs, and supercharge returns.",
      },
    ],
  },
  {
    number: "04",
    title: "Web3 & Blockchain",
    caption: "Ignite Your Web3 Journey with Unrivalled Expertise",
    objective: "OG Pass",
    hasObjectiveLabel: true,
    milestoneTitle: "Next -",
    milestoneSubTitle: "1st Collab",
    milestoneLabel: "in progress",
    hasMilestoneLabel: true,
    progressionPercentage: 80,
    video: Home,
    contents: [
      {
        title: "Web3 Ready",
        description:
          "Full-spectrum support for your web3 endeavors. From DApps to DeFi, we’re your web3 backbone.",
      },
      {
        title: "Smart Contract Excellence",
        description:
          "Crafting and auditing secure smart contracts across blockchains. We promise seamless, fail-proof transactions.",
      },
      {
        title: "From Idea to On-Chain",
        description:
          "Incubate and launch on-chain projects with our expert playbook. We’re with you, concept to code.",
      },
      {
        title: "Stable Web3 Solutions",
        description:
          "Beyond immediate needs, we build enduring, scalable web3 assets. Trust in our tech for continuous growth.",
      },
      {
        title: "Blockchain Forward",
        description:
          "Unlock decentralized potential. We guide you through the blockchain frontier for secure, transparent ventures.",
      },
    ],
  },
];
