import React, { FC } from "react";
export { Head } from "src/utils/head";

import { Layout } from "components/template/layout";

import { MindMapCardSectionContainer } from "./_mindmapCardSection";

const MindmapPage: FC = () => {
  return (
    <Layout>
      <MindMapCardSectionContainer />
    </Layout>
  );
};

export default MindmapPage;
