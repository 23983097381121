import React, { FC, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Title } from "components/atoms/title";
import { Text } from "components/atoms/text";
import { MINDMAP_DATA } from "src/utils/mindmap";

import { MindmapCard } from "./_mindmapCard";

const MindmapSectionContainer = styled.section`
  height: calc(100vh - 120px);
  margin-top: 120px;
  padding: 0 80px;

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    padding: 0 60px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    padding: 0 40px;
  }
`;

const TitleContainer = styled.div`
  max-width: 1000px;
`;

const HorizontalBar = styled.hr`
  margin-bottom: 40px;
  background: ${({ theme }) => theme.colors.gray["400"]};
  height: 1px;
  border-width: 0;

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    margin-bottom: 40px;
  }
`;

const ModalContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 80px);
  margin-top: 80px;
  background: #00000070;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Modal = styled.div`
  width: 80%;
  max-height: 90%;
  padding: 40px 60px;
  background: #fff;

  @media (max-width: ${({ theme }) => theme.device.portraitTablet}px) {
    padding: 16px 32px;
    width: 95%;
  }
`;

const ModalGrid = styled.div`
  display: grid;
  align-self: center;
  grid-template-columns: 1.7fr 1.3fr;
  column-gap: 60px;
  row-gap: 40px;

  @media (max-width: ${({ theme }) => theme.device.portraitTablet}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ModalScrollableText = styled.div`
  overflow-x: scroll;
  max-height: 400px;

  @media (max-width: ${({ theme }) => theme.device.landscapeTablet}px) {
    max-height: 200px;
  }
`;

const ModaleVideo = styled.video`
  width: 80%;
  height: 70%;
  border-radius: 4px;
  object-fit: cover;

  @media (max-width: ${({ theme }) => theme.device.portraitTablet}px) {
    width: 270px;
  }
`;

const ModaleImage = styled.img`
  width: 80%;
  height: 80%;
  border-radius: 2px;
  justify-self: center;
  object-fit: cover;

  @media (max-width: ${({ theme }) => theme.device.portraitTablet}px) {
    width: 270px;
  }
`;

const ScrollableContainer = styled.div`
  display: flex;
  height: 455px;
  overflow: hidden;
  @media (max-width: ${({ theme }) => theme.device.portraitTablet}px) {
    height: 400px;
    margin: 0 -40px;
  }
`;
const Scrollable = styled.div`
  height: 465px;
  display: flex;
  overflow-x: scroll;
  @media (max-width: ${({ theme }) => theme.device.portraitTablet}px) {
    height: 410px;
  }
`;

const ModalContentTitle = styled(Text)`
  min-width: 160px;

  @media (max-width: ${({ theme }) => theme.device.portraitTablet}px) {
    min-width: 100px;
  }

  @media (max-width: ${({ theme }) => theme.device.mobile}px) {
    min-width: 60px;
  }
`;

// const Divider = styled.hr`
//   background: ${({ theme }) => theme.colors.gray["300"]};
//   height: 1px;
//   min-width: 22px;
//   border-width: 0;

//   @media (max-width: ${({ theme }) => theme.device.mobile}px) {
//     margin-bottom: 40px;
//   }
// `;

export const MindMapCardSectionContainer: FC = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<any>(null);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = (e: any) => {
    setModalContent(null);
    setIsOpen(false);
  };

  return (
    <MindmapSectionContainer>
      <TitleContainer>
        <Title type="h2" mb={30} fontSize="80">
          {t("solutions.title")}
        </Title>
      </TitleContainer>
      <HorizontalBar />

      <ScrollableContainer>
        <Scrollable>
          {MINDMAP_DATA.map((datum, i) => (
            <MindmapCard
              datum={datum}
              key={i}
              openModal={openModal}
              setModalContent={setModalContent}
            />
          ))}
        </Scrollable>
      </ScrollableContainer>

      {isOpen && modalContent && (
        <ModalContainer onClick={closeModal}>
          <Modal onClick={(e) => e.stopPropagation()}>
            <Title
              type="h4"
              fontSize="67"
              mb={24}
              fontFamily="Post Grotesk Thin"
              color="#A5A1A1"
            >
              {modalContent.number} {modalContent.title}
            </Title>
            <HorizontalBar />
            <ModalGrid>
              <ModalScrollableText>
                {modalContent.contents.map((content, i) => (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "16px",
                    }}
                  >
                    <ModalContentTitle
                      mobilefontsize="12"
                      mr={0}
                      fontFamily="Post Grotesk"
                      bold
                    >
                      {content.title}
                    </ModalContentTitle>
                    <hr
                      style={{
                        minWidth: "22px",
                        height: "1px",
                        borderWidth: 0,
                        background: "gray",
                      }}
                    />
                    <Text
                      mobilefontsize="12"
                      ml={40}
                      fontFamily="Post Grotesk Thin"
                    >
                      {content.description}
                    </Text>
                  </div>
                ))}
              </ModalScrollableText>

              {modalContent.image && (
                <ModaleImage src={modalContent.image} alt="" />
              )}
              {modalContent.video && (
                <ModaleVideo autoPlay muted loop>
                  <source src={modalContent.video} type="video/mp4" />
                </ModaleVideo>
              )}
            </ModalGrid>
          </Modal>
        </ModalContainer>
      )}
    </MindmapSectionContainer>
  );
};
